import React from "react"
import { Box } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const OuterImmage = ({ sx = {}, imgSx = {}, img: { localFile } }) => (
  <Box
    sx={{
      ...sx,
      borderRadius: 3,
      overflow: "hidden",
      img: { borderRadius: 3, ...imgSx },
      display: "flex",
    }}
  >
    <GatsbyImage image={getImage(localFile)} alt="office image" />
  </Box>
)
const InnerImage = ({ sx = {}, imgSx = {}, img: { localFile } }) => (
  <Box
    sx={{
      display: "flex",
      width: 0.8,
      borderRadius: 3,
      overflow: "hidden",
      img: { borderRadius: 3, ...imgSx },
      ...sx,
    }}
  >
    <GatsbyImage image={getImage(localFile)} alt="office image" />
  </Box>
)

const ZigZagPhotoCollage = ({
  upperLeft,
  upperRight,
  lowerLeft,
  lowerRight,
}) => {
  return (
    <Box display="flex" gap={2.5}>
      <Box flex={1} display="flex" flexDirection="column" gap={2.5}>
        <OuterImmage img={upperLeft} sx={{ justifyContent: "flex-end" }} />
        <InnerImage
          img={lowerLeft}
          sx={{ justifyContent: "flex-end", alignSelf: "flex-end" }}
          imgSx={{ ml: "auto" }}
        />
      </Box>
      <Box flex={1} display="flex" flexDirection="column" gap={2.5}>
        <InnerImage img={upperRight} sx={{ pt: 2.5 }} />
        <OuterImmage img={lowerRight} />
      </Box>
    </Box>
  )
}

export default ZigZagPhotoCollage
