import React from "react"
import { Box } from "@mui/material"

import Layout from "../components/layout"
import { ContactCard } from "./articles/{strapiArticle.strapi_id}"
import { graphql, useStaticQuery } from "gatsby"

import RichText from "../components/RichText"
import ZigZagPhotoCollage from "../components/ZigZagPhotoCollage"
import { useTheme } from "@mui/system"
import FeaturedResources from "../components/featured-resources"

const AboutUs = () => {
  const { palette } = useTheme()

  const {
    strapiAboutUs: {
      firstSection: {
        section: {
          data: { section: firstSection },
        },
      },
      firstCollage,
      secondSection: {
        section: {
          data: { section: secondSection },
        },
      },
      secondCollage,
      notableAwards: {
        section: {
          data: { section: notableRewards },
        },
      },
    },
    allStrapiArticle: { nodes: featuredArticles },
  } = useStaticQuery(aboutUsQuery)

  return (
    <Layout>
      <Box
        mt={{ xs: 1, lg: 9 }}
        display="flex"
        flexDirection="column"
        maxWidth={835}
        mx="auto"
        gap={10}
      >
        <RichText
          sx={{
            "h6:first-child": {
              color: palette.primary.main,
              textTransform: "uppercase",
              fontSize: { xs: "0.9615rem", sm: "1.1538rem" },
              fontWeight: 600,
              letterSpacing: "0.33px",
              lineHeight: 1.3,
              mb: { xs: 0, lg: 0.5 },
            },
          }}
        >
          {firstSection}
        </RichText>
        <ContactCard />
        <ZigZagPhotoCollage {...firstCollage} />
        <RichText>{secondSection}</RichText>
        <ZigZagPhotoCollage {...secondCollage} />
        <RichText
          sx={{
            ul: { listStyle: "none", p: 0, mt: 2.5 },
            li: {
              mb: 2.5,
              pl: 3.5,
            },
            "li::before": {
              content: '"•"',
              color: palette.primary.main,
              fontSize: "2rem",
              fontWeight: "bold",
              lineHeight: 0,
              marginRight: 1.5,
              marginLeft: -3.5,
            },
          }}
        >
          {notableRewards}
        </RichText>
      </Box>
      <FeaturedResources
        resourceURI="/articles"
        overline="Case studies"
        title="Featured resources"
        resources={featuredArticles}
      />
    </Layout>
  )
}

const aboutUsQuery = graphql`
  query AboutUsQuery {
    strapiAboutUs {
      firstSection {
        section {
          data {
            section
          }
        }
      }
      firstCollage {
        upperLeft {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        upperRight {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        lowerLeft {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        lowerRight {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      secondSection {
        section {
          data {
            section
          }
        }
      }
      secondCollage {
        upperLeft {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        upperRight {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        lowerLeft {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        lowerRight {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      notableAwards {
        section {
          data {
            section
          }
        }
      }
    }

    allStrapiArticle(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      nodes {
        strapi_id
        readTime
        publishedAt
        header {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            url
          }
        }
      }
    }
  }
`

export default AboutUs
